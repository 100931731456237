const data = JSON.stringify({
  total_pages: 6,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: '증상 변화',
      description: '',
      questions: [
        {
          id: 999,
          nr: 999,
          show_id: false,
          question: "첫 번째 설문지(Part A)를 작성한 이후에 어떠한 증상의 변화를 겪었는지 체크해주십시오.",
          answers: [
            {
              id: 1,
              desc: "",
              name: "증상이 매우 좋아졌다 (모든 증상이 사라짐)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "증상이 많이 좋아졌다 (대부분의 증상이 사라짐)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "증상이 다소 좋아졌다 (증상이 일부 호전됨)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "증상의 변화가 거의 없다 (이전과 증상이 동일함)",
              points: 3
            },
            {
              id: 5,
              desc: "",
              name: "증상이 나빠졌다 (증상이 악화됨)",
              points: 4
            }
          ]
        },
      ]
    },
    {
      id: 2,
      page_nr: 2,
      subtitle: '대표적 증상',
      description: '지난 24시간 이내에 아래 증상이 있었는지 표시하고 그 강도를 평가하십시오.',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "적은 소변양으로 빈뇨가 있음",
          answers: [
            {
              id: 1,
              desc: "하루 4회 이하",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "하루 5-6회",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "하루 7-8회",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "하루 9-10회 이상",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "절박뇨(강하고 조절할 수 없는 요의)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "소변을 볼 때 통증 또는 화끈거림이 있음",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "방광이 완전히 비워지지 않은 느낌(배뇨 후에도 소변을 여전히 다시 보고 싶은 느낌)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "배뇨와 관련 없는 하복부의 통증(배꼽 아래)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "혈뇨 보임(생리 아님)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 3,
      page_nr: 3,
      subtitle: '감별',
      description: '지난 24시간 이내에 아래 증상이 있었는지 표시하고 그 강도를 평가하십시오.',
      questions: [
        {
          id: 7,
          nr: 7,
          show_id: true,
          question: "옆구리 통증(허리 한쪽 혹은 양쪽 통증)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
        {
          id: 8,
          nr: 8,
          show_id: true,
          question: "비정상적인 질분비물(비정상적인 양, 색깔, 냄새 등)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
        {
          id: 9,
          nr: 9,
          show_id: true,
          question: "평상시 소변이 요도에서 새어 나옴(요실금)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
        {
          id: 10,
          nr: 10,
          show_id: true,
          question: "발열 및 오한",
          answers: [
            {
              id: 1,
              desc: "≤37.5°C",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "37.6 – 37.9°C",
              name: "약간 그렇다",
              points: 1
            },
            {
              id: 3,
              desc: "38.0-38.9°C",
              name: "그렇다",
              points: 2
            },
            {
              id: 4,
              desc: "≥39.0°C",
              name: "매우 그렇다",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 4,
      page_nr: 4,
      subtitle: '삶의 질',
      description: '',
      questions: [
        {
          id: 11,
          nr: 11,
          show_id: true,
          question: "최근 24시간 동안 상기 증상으로 인한 불편한 느낌이 어느 정도인지 체크해주십시오.",
          answers: [
            {
              id: 1,
              desc: "",
              name: "불편감 없음(전혀 증상 없음. 평소처럼 기분 좋음)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "경미한 불편감 (평소보다 기분이 조금 나쁨)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "중등도의 불편감(평소 보다 기분이 훨씬 나쁨)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "많이 불편함(기분이 매우 나쁨)",
              points: 3
            },
          ]
        },
        {
          id: 12,
          nr: 12,
          show_id: true,
          question: "최근 24시간 동안 상기 증상이 일상 활동/업무에 어느 정도 영향을 끼쳤는지 체크해주십시오.",
          answers: [
            {
              id: 1,
              desc: "",
              name: "전혀 지장을 주지 않음(근무일 에 정상적으로 근무함)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "약간 지장을 줌(증상으로 인해 일을 약간 적게 함)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "중등도로 지장을 줌(일 상 업무에 노력을 요함)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "심하게 지장을 줌(거의 일 할 수 없음)",
              points: 3
            },
          ]
        },
        {
          id: 13,
          nr: 13,
          show_id: true,
          question: "최근 24시간 동안 상기 증상이 사회활동(모임 참석, 친구와 만남 등)에 어느 정도 방해됐는지 체크해주십시오.",
          answers: [
            {
              id: 1,
              desc: "",
              name: "전혀 방해되지 않음(평소과 같 이 사회활동을 함)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "조금 방해됨 (일부 사회 활동을 못함)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "방해됨(사회활동이 상 당히 줄어듦)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "심하게 방해됨(거의 외출하 지 못함)",
              points: 3
            },
          ]
        },
      ]
    },
    {
      id: 5,
      page_nr: 5,
      subtitle: '추가 질문',
      description: '설문지 작성 시 다음 사항에 해당하는지 표시하십시오',
      questions: [
        {
          id: 14,
          nr: 14,
          show_id: true,
          question: "생리중이십니까?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "예",
              points: "yes"
            }
          ]
        },
        {
          id: 15,
          nr: 15,
          show_id: false,
          question: "생리 전 증후군(PMS)이 있습니까?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "예",
              points: "yes"
            }
          ]
        },
        {
          id: 16,
          nr: 16,
          show_id: false,
          question: "갱년기 증상(예. 홍조)이 있습니까?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "예",
              points: "yes"
            }
          ]
        },
        {
          id: 17,
          nr: 17,
          show_id: false,
          question: "임신 중이십니까?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "예",
              points: "yes"
            }
          ]
        },
        {
          id: 18,
          nr: 18,
          show_id: false,
          question: "당뇨가 있습니까?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "예",
              points: "yes"
            }
          ]
        },
      ]
    },
    {
      id: 6,
      page_nr: 6,
      subtitle: '',
      description: ''
    }
  ],
})

export default data
